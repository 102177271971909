import React from 'react';
import {
  AppBarLink,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Link, useRouteMatch } from 'react-router-dom';

import { LinkType, NavLink } from 'hooks/use-nav-links-list.hook';
import { Text } from '@leagueplatform/genesis-commons';
import {
  Box,
  Icon,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';

type NavLinkWrapperProps = {
  navLink: NavLink;
};

const sendNavBarAnalyticsEvent = (action: string) => () =>
  trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
    screen_name: 'Navigation Bar',
    detail: action,
  });

export const NavLinkWrapper = ({ navLink }: NavLinkWrapperProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const match = useRouteMatch({
    path: navLink.to as string,
    exact: false,
  });

  const isActive = match !== null;

  const image = isActive ? navLink.iconSelected : navLink.icon;
  switch (navLink.type) {
    case LinkType.LINK:
      return (
        <AppBarLink
          as={Link}
          to={navLink.to}
          key={navLink.message_id}
          onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
          color="onSurface.text.primary"
          hoverStyle={{ color: 'interactive.action.primary' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {formatMessage({ id: navLink.message_id })}
        </AppBarLink>
      );
    case LinkType.NAV:
      return (
        <AppBarNavLink
          to={navLink.to}
          key={navLink.message_id}
          onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
          color="onSurface.text.primary"
          hoverStyle={{ color: 'interactive.action.primary' }}
          decorativeBarColor="interactive.action.primary"
          display="flex"
          justifyContent="center"
          alignItems="center"
          isMobile={isMobile}
          listItemProps={{
            justifyContent: 'center',
          }}
        >
          {isMobile ? (
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                minWidth: '$four',
              }}
            >
              <Icon icon={image as string} size="$oneAndHalf" />
              <Text
                fontSize="overline"
                fontWeight="regular"
                paddingTop="quarter"
              >
                {formatMessage({ id: navLink.message_id })}
              </Text>
            </Box>
          ) : (
            <Box css={{ justifyContent: 'center' }}>
              {formatMessage({ id: navLink.message_id })}
            </Box>
          )}
        </AppBarNavLink>
      );
    default:
      throw new Error('invalid link type');
  }
};
