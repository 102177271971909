import React, { useEffect } from 'react';
import {
  Box,
  queryHelpers,
  StackLayout,
  TextAction,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import {
  InternalOrExternalLink,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import Masonry from '@leagueplatform/masonry';
import { LEGAL_DETAILS_QUERY_PARAM_KEY, MASONRY } from 'common/constants';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

type LinkData = {
  heading: string;
  link_url: string;
  focused?: boolean;
};

export const LegalLink = ({ widget }: { widget: ContainerWidgetItem }) => {
  const { data, isLoading, error } = useGetWidgetData<LinkData>(
    widget,
    MASONRY.API_VERSION,
  );
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      !isMobile &&
      data?.attributes?.link_url &&
      data?.attributes?.focused &&
      !searchParams.get(LEGAL_DETAILS_QUERY_PARAM_KEY)
    ) {
      history.push(data?.attributes?.link_url);
    }
  }, [location, history, isMobile, path, data]);

  if (isLoading || error || !data?.attributes?.link_url) {
    return null;
  }

  const isActive =
    location.pathname.includes(APP_MANAGED_ROUTES.LEGAL_DETAILS_SUBPATH) &&
    location.search.includes(LEGAL_DETAILS_QUERY_PARAM_KEY) &&
    data?.attributes?.link_url?.includes(location.search) &&
    !isMobile;

  return (
    <Box>
      <InternalOrExternalLink href={data?.attributes?.link_url}>
        <TextAction
          as="span"
          indicator={
            isAbsoluteUrl(data?.attributes?.link_url) && 'externalLink'
          }
          css={{
            padding: isMobile ? '$half none' : '$half $one',
            color: isActive
              ? '$onSurfaceTextPrimary'
              : '$interactiveActionPrimary',
            borderLeft: isActive
              ? 'medium solid $interactiveActionPrimary'
              : 'none',
          }}
        >
          {data?.attributes?.heading}
        </TextAction>
      </InternalOrExternalLink>
    </Box>
  );
};

const LegalMasonry = Masonry.init({
  apiVersion: MASONRY.API_VERSION,
  appId: 'hm_legal',
  analytics: {
    productArea: 'legal screen',
    screenName: 'legal screen',
  },
  uiConfig: {
    CustomLoader: () => (
      <StackLayout css={{ position: 'relative' }}>
        <LoadingIndicator />
      </StackLayout>
    ),
  },
  customWidgetModules: [
    {
      moduleKey: MASONRY.MODULE_KEY_CUSTOM_WIDGETS,
      customWidgetsUI: [
        {
          type: 'hm_legal_link',
          widget: ({ widget }) => <LegalLink widget={widget} />,
        },
      ],
    },
  ],
});

export const LegalSidebar = () => (
  <Box
    css={{
      minWidth: '320px',
      ' > div': {
        gap: '$threeQuarters',
      },
    }}
  >
    <LegalMasonry.Provider />
  </Box>
);
