import { Route, useRouteMatch } from 'react-router-dom';
import { PWPMainPage } from './pwp-main.page';

/**
 * Medicare Advantage Personalized Wellness Plan Routes
 */
export const PWPRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Route exact path={[path, `${path}/:id`]}>
      <PWPMainPage />
    </Route>
  );
};
