import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  styled,
  TextAction,
} from '@leagueplatform/genesis-core';
import React from 'react';
import { PageHeaderBackground } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import planEndedIcon from 'assets/plan-period-ended.svg';
import { Image } from '@leagueplatform/genesis-commons';
import { useHistory } from 'react-router-dom';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { CONTENT_ROUTES } from 'api/get-app-init-details';

const maximumWidth = 1280;

const Wrapper = styled(Box, {
  '@desktop': {
    marginLeft: 'calc(100vw - 100%)',
    marginRight: 0,
  },
});

export const PlanEndedBlockerPage = ({
  contentRoute,
}: {
  contentRoute: CONTENT_ROUTES;
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleClick = (screenName: PRODUCT_AREA) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: screenName,
      product_area: screenName,
      detail: 'Get support',
      sub_detail: 'Plan period has ended',
      sub_product_area: screenName,
    });
    history.push(APP_MANAGED_ROUTES.SUPPORT);
  };

  return (
    <Wrapper css={{ padding: '$none $two' }}>
      <PageHeaderBackground>
        <Box
          css={{
            display: 'flex',
            width: '100%',
            maxWidth: maximumWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'space-between',
          }}
        >
          <HeadingText size="xxl" level="1" css={{ marginTop: '$twoAndHalf' }}>
            {formatMessage({
              id: contentRoute === CONTENT_ROUTES.CARE ? 'GET_CARE' : 'JOURNEY',
            })}
          </HeadingText>
        </Box>
      </PageHeaderBackground>
      <Box
        css={{ paddingTop: '60px', '@mobile': { paddingTop: '$oneAndHalf' } }}
      >
        <StackLayout
          spacing="$half"
          horizontalAlignment="center"
          css={{ width: '100%' }}
        >
          <Image
            src={planEndedIcon}
            alt={formatMessage({ id: 'BLOCKER_ALT_TEXT' })}
            width="176px"
            height="176px"
            marginBottom="one"
          />
          <HeadingText size="lg" level="2">
            {formatMessage({ id: 'BLOCKER_TITLE' })}
          </HeadingText>
          <ParagraphText css={{ maxWidth: '380px', textAlign: 'center' }}>
            {formatMessage({ id: 'BLOCKER_DESCRIPTION' })}
          </ParagraphText>
          <TextAction
            as="button"
            onClick={() =>
              handleClick(
                contentRoute === CONTENT_ROUTES.CARE
                  ? PRODUCT_AREA.GET_CARE
                  : PRODUCT_AREA.JOURNEY,
              )
            }
          >
            {formatMessage({ id: 'GET_SUPPORT_SUBTITLE' })}
          </TextAction>
        </StackLayout>
      </Box>
    </Wrapper>
  );
};
