import React, { useEffect, useState } from 'react';
import healthyAging from 'assets/pwp/healthy-aging.svg';
import starIcon from 'assets/pwp/icon-star-circle.svg';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  Icon,
  ParagraphText,
  StackItem,
  StackLayout,
  StatusBadge,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { ProgramHeading } from 'components/pwp/program-heading.component';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';

type PwpHeaderProps = {
  heading?: string;
  statusBannerText?: string;
  imageUrl?: string;
  startDate?: string;
  endDate?: string;
  programStatus?: string;
  earnedMoney: number;
  completedActivitiesCount: number;
  totalActivitiesCount: number;
  hideProgressBar?: boolean;
};

export const PwpHeader = ({
  heading = '',
  statusBannerText = '',
  imageUrl = healthyAging,
  startDate = '',
  endDate = '',
  programStatus = '',
  earnedMoney,
  completedActivitiesCount,
  totalActivitiesCount,
  hideProgressBar = false,
}: PwpHeaderProps) => {
  const [isValidDates, setIsValidDates] = useState(false);
  const [verifiedStartDate, setStartDate] = useState('');
  const [verifiedEndDate, setEndDate] = useState('');
  const { formatMessage, formatDate } = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (startDate && endDate) {
      try {
        setStartDate(
          formatDate(startDate, { ...SHORT_DATE_FORMAT, timeZone: 'UTC' }),
        );
        setEndDate(
          formatDate(endDate, { ...SHORT_DATE_FORMAT, timeZone: 'UTC' }),
        );
        setIsValidDates(true);
      } catch {
        setIsValidDates(false);
      }
    }
  }, [startDate, endDate, formatDate]);

  // If the user doesn't have MA wellness program (BE sends null attributes), return the user to health journey home
  if (!earnedMoney && earnedMoney !== 0) {
    history.push(getModulePath(LEAGUE_MODULE_NAMES.healthJourney));
  }

  return (
    <StackLayout
      css={{
        gap: '$half',
        marginBottom: '$oneAndHalf',
        '@mobile': { marginBottom: '$one' },
      }}
    >
      <ProgramHeading
        badge={
          statusBannerText
            ? {
                css: {
                  borderRadius: '$medium',
                  backgroundColor: '$highlightBackgroundSubdued',
                },
                iconTextProps: {
                  iconProps: {
                    tint: '$decorativeBrandPrimaryDefault',
                    icon: 'illustrativeHeartFilled',
                    size: '16px',
                  },
                  text: (
                    <ParagraphText css={{ typography: '$overline' }}>
                      {statusBannerText}
                    </ParagraphText>
                  ),
                },
              }
            : undefined
        }
        image={imageUrl || ''}
        title={heading || ''}
      >
        {isValidDates && (
          <UtilityText
            css={{
              typography: '$bodyTwo',
            }}
          >{`Starts ${verifiedStartDate} • Ends ${verifiedEndDate}`}</UtilityText>
        )}
        {programStatus && (
          <StatusBadge
            css={{
              marginTop: 'threeQuarters',
            }}
            label={`${programStatus[0].toUpperCase()}${programStatus.slice(1)}`}
            showLight
            // Currently, there is no design for a program beyond active status, so we always mark as success status
            status="success"
          />
        )}
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          horizontalAlignment="center"
          css={{ width: '100%', marginTop: '$one' }}
        >
          <Icon icon={starIcon} size="sm" css={{ marginRight: '$quarter' }} />
          <UtilityText emphasis="emphasized">
            {`$${earnedMoney} ${formatMessage({ id: 'WELLNESS_REWARDS' })}`}
          </UtilityText>
        </StackLayout>
      </ProgramHeading>

      {!hideProgressBar && (
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          horizontalAlignment="start"
        >
          <UtilityText
            css={
              completedActivitiesCount > 0
                ? { color: '$onSurfaceTextSuccess', typography: '$bodyTwo' }
                : { typography: '$bodyTwo' }
            }
          >
            {completedActivitiesCount}
          </UtilityText>
          <UtilityText css={{ typography: '$bodyTwo' }}>
            &nbsp;
            {`/ ${totalActivitiesCount} ${formatMessage({ id: 'ACTIVITIES' })}`}
          </UtilityText>
        </StackLayout>
      )}
      {!hideProgressBar && (
        <StackItem css={{ width: '100%' }}>
          <ProgressBar
            customBarFillColor="success.border.default"
            customBarBackgroundColor="interactive.background.disabled"
            current={completedActivitiesCount}
            total={totalActivitiesCount}
          />
        </StackItem>
      )}
    </StackLayout>
  );
};
