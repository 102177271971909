import React from 'react';
import {
  Router,
  type BrowserRouterProps as BrowserRouterPropsBase,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

type BrowserRouterProps = Omit<BrowserRouterPropsBase, 'basename'>;

export const BrowserRouter = (props: BrowserRouterProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Router history={history} {...props} />
);
