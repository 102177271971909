import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BodyOne,
  Flex,
  HeadingTwo,
  Image,
  PrimaryButton,
  QuietButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import { FullScreenContainer } from 'components/full-screen-container.component';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HIGHMARK_COMMON_ASSET_KEYS } from 'types/highmark-common.types';
import { MfaEnrollmentModal } from './mfa-enrollment-modal.component';

export const MfaEnrollment = ({
  onEnrollSuccess,
}: {
  onEnrollSuccess: () => void;
}) => {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const { formatMessage } = useIntl();
  const privacyImage = handleStaticAsset(
    HIGHMARK_COMMON_ASSET_KEYS.HIGHMARK_PRIVACY_IMAGE,
  ) as string;

  return (
    <FullScreenContainer backgroundColor="surface.background.primary">
      <StackLayout
        space="$two"
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          maxWidth: '327px',
        }}
      >
        <Image
          display="block"
          marginX="auto"
          marginTop="four"
          width="160px"
          height="160px"
          src={privacyImage}
          alt="privacy"
        />
        <StackLayout css={{ textAlign: 'center' }}>
          <HeadingTwo>{formatMessage({ id: 'MFA_TITLE' })}</HeadingTwo>
          <BodyOne>{formatMessage({ id: 'MFA_DESCRIPTION' })}</BodyOne>
        </StackLayout>
        <Flex justifyContent="space-between">
          <QuietButton onClick={() => history.push('/sign-out')}>
            {formatMessage({ id: 'SIGN_OUT' })}
          </QuietButton>
          <PrimaryButton onClick={() => setOpen(true)}>
            {formatMessage({ id: 'SET_UP_NOW' })}
          </PrimaryButton>
        </Flex>
      </StackLayout>
      {isOpen && (
        <MfaEnrollmentModal
          closeModal={() => setOpen(false)}
          onEnrollSuccess={onEnrollSuccess}
        />
      )}
    </FullScreenContainer>
  );
};
