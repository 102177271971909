import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  PrimaryButton,
  QuietButton,
} from '@leagueplatform/genesis-commons';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useHistory } from 'react-router-dom';
import { useIntl } from '@leagueplatform/locales';
import { HsaHeader } from 'components/hsa/hsa-header.component';
import { HsaBody } from 'components/hsa/hsa-body.component';
import { HsaSuccessModal } from 'components/hsa/hsa-success-modal.component';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useGetHsaRegistrationEligibility } from 'hooks/use-get-hsa-registration-eligibility.hook';
import { useOpenHsaAccount } from 'hooks/use-open-hsa-account';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';

type HsaPageProps = {
  openHsaImage: string;
  hsaAccountCreatedImage: string;
};

export const HsaPage = ({
  openHsaImage,
  hsaAccountCreatedImage,
}: HsaPageProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const {
    data: hsaEligibility,
    isLoading: isHsaLoading,
    isIdle: isHsaIdle,
    isError: isGetRegistrationEligibilityError,
  } = useGetHsaRegistrationEligibility();
  const {
    isLoading: isRegistrationLoading,
    isError: isOpenHsaAccountError,
    isSuccess,
    mutateAsync,
  } = useOpenHsaAccount();

  const isLargeScreen = useMediaQuery(queryHelpers.up('tablet'));

  useEffect(() => {
    if (isSuccess) {
      setShowSuccessModal(true);
    }
    if (isOpenHsaAccountError || isGetRegistrationEligibilityError) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_DESCRIPTION_GENERIC',
      });
    }
  }, [isSuccess, isOpenHsaAccountError, isGetRegistrationEligibilityError]);

  if (
    !isHsaIdle &&
    !isHsaLoading &&
    !hsaEligibility?.isHsaRegistrationEligible
  ) {
    history.push('/home');
    return null;
  }

  const handleRegisterHsaAction = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: 'Open your HSA page',
      detail: 'HSA Submit Enrollment',
    });
    mutateAsync(hsaEligibility);
  };

  return (
    <MainPageContainer padding={{ _: 'one', tablet: 'oneAndHalf' }}>
      <HsaHeader />
      <Flex marginLeft={{ _: '0', laptop: 'three' }}>
        {isLargeScreen && (
          <Image
            src={openHsaImage}
            alt="person holding piggy bank"
            height="225px"
            width="225px"
            marginRight="three"
          />
        )}
        <Box marginLeft={{ _: 'none', tablet: 'one' }}>
          <HsaBody />
          {isHsaLoading || isHsaIdle || isRegistrationLoading ? (
            <LoadingSpinner />
          ) : (
            <Flex
              justifyContent="space-evenly"
              alignItems="center"
              marginY={{ _: 'two', laptop: 'five' }}
              flexWrap="wrap-reverse"
            >
              <QuietButton onClick={() => history.push('/home')}>
                {formatMessage({ id: 'HSA_PAGE_CTA_SECONDARY' })}
              </QuietButton>
              <PrimaryButton
                disabled={isHsaLoading || isHsaIdle || isRegistrationLoading}
                onClick={handleRegisterHsaAction}
                width="328px"
              >
                {formatMessage({ id: 'HSA_CTA' })}
              </PrimaryButton>
            </Flex>
          )}
        </Box>
      </Flex>
      <HsaSuccessModal
        onClose={() => {
          setShowSuccessModal(false);
          history.push('/home');
        }}
        isOpen={showSuccessModal}
        image={hsaAccountCreatedImage}
      />
    </MainPageContainer>
  );
};
