import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BackgroundImage,
  Box,
  Flex,
  Image,
  PrimaryButton,
  Text,
} from '@leagueplatform/genesis-commons';
import { useAuth } from '@leagueplatform/auth-utils';

import landingPageImage from 'assets/landing-page-image.jpg';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { clearHighmarkSessionRedirect } from 'utils/clear-session-redirect';
import { captureAuthenticationError } from 'utils/sentry-helpers';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

type LandingPageProps = {
  logo: string;
  landingGreeting: string;
  buttonText: string;
  authErrorText: string;
  footer?: React.ReactNode;
};

const LandingImageBox = () => (
  <Box flexDirection="column" flex={{ _: '0', tablet: '1' }}>
    <BackgroundImage
      alt=""
      src={landingPageImage}
      role="presentation"
      data-testid="background-image"
      objectFit="cover"
      height={{ _: '40vh', tablet: '100vh' }}
      backgroundPosition={{ _: '50% 10%', tablet: '0 top' }}
    />
  </Box>
);

export const LandingPage = ({
  logo,
  landingGreeting,
  buttonText,
  authErrorText,
  footer,
}: LandingPageProps) => {
  const history = useHistory();
  const { isAuthenticated, error } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      history.push({
        pathname: APP_MANAGED_ROUTES.HOME,
      });
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    if (error) {
      captureAuthenticationError(error);
    }
  }, [error]);

  return (
    <Box
      as="main"
      position="relative"
      flex="1"
      textAlign="center"
      minHeight="100vh"
    >
      {isAuthenticated === undefined ? (
        <LoadingSpinner />
      ) : (
        <Flex height="100vh" flexDirection={{ _: 'column', tablet: 'row' }}>
          <LandingImageBox />
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flex="1"
          >
            <Flex
              maxWidth="350px"
              alignSelf="center"
              flexDirection="column"
              margin="1rem auto"
              flex="1"
              justifyContent="center"
            >
              <Image
                src={logo}
                alt="logo"
                maxHeight={{ _: '100px', phone: '120px' }}
              />
              <Text fontSize="body1" marginY="twoAndHalf" paddingX="35px">
                {landingGreeting}
              </Text>
              {isAuthenticated === false && (
                <PrimaryButton
                  maxWidth="327px"
                  width="100%"
                  alignSelf="center"
                  // Temp solution to clear bad session cookies before login using redirects: https://everlong.atlassian.net/browse/HIGHMARK-2846
                  onClick={() =>
                    clearHighmarkSessionRedirect(APP_MANAGED_ROUTES.SIGN_IN)
                  }
                >
                  {buttonText}
                </PrimaryButton>
              )}
              {error && (
                <Text color="onSurface.text.critical">{authErrorText}</Text>
              )}
            </Flex>
            <Box
              paddingBottom={{ _: 'two', tablet: 'three', desktop: 'four' }}
              width="100%"
              margin="0 auto"
            >
              {footer}
            </Box>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
