import React from 'react';
import { useGetIsImpersonator } from '@leagueplatform/auth-utils';
import { Route, Switch } from 'react-router-dom';
import { impersonationBlockedRoutes } from 'common/app-managed-routes';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { ImpersonationErrorComponent } from '../impersonation-error/impersonation-error.component';

export const ImpersonationBlockerSwitch = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const isImpersonation = useGetIsImpersonator();

  if (isImpersonation === null) return <LoadingSpinner />;

  return (
    <Switch>
      {isImpersonation && (
        <Route
          path={impersonationBlockedRoutes}
          component={ImpersonationErrorComponent}
        />
      )}
      {children}
    </Switch>
  );
};
