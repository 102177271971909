import { useHistory, useLocation } from 'react-router-dom';

// /**
//  * Return object of
//  * @typedef {HistoryGoBackReturn}
//  * @property {function} goBack - function that can be called by the component to execute the correct back navigation
//  */

/**
 * Checks if previous browser history location is managed by the app.
 * If previous path is from within the app, go back in browser history as normal.
 * If previous path is external or this is a new tab,
 * navigate to the provided fallbackPath instead of the previous browser history.
 *
 * @param fallbackPath fallback path to push as new route if previous route is not an in-app route such as google.come. If this param is not set, it will default to "/".
 *
 * @returns {{goBack: function}} goBack - function that can be called by the component to execute the correct back navigation
 */
export const useHistoryGoBack = (fallbackPath: string = '/') => {
  const location = useLocation();
  const history = useHistory();
  return {
    goBack: () =>
      // If location.key is undefined (react-router v5) or "default" (react-router v6), previous route is not from within app.
      location.key && location.key !== 'default'
        ? history.goBack()
        : history.push(fallbackPath),
  };
};
