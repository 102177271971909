import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ControlledFieldset,
  FormProvider,
  useForm,
} from '@leagueplatform/web-common';
import { useHistory } from 'react-router-dom';
import { Button } from '@leagueplatform/genesis-core';
import { reportAgentPermissionError } from '@leagueplatform/core';
import { useGetIsImpersonator } from '@leagueplatform/auth-utils';
import { RegionSelectionContext } from 'components/header-nav/region-selection-context';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { useSetUserProfile } from 'hooks/use-set-user-profile';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useQueryClient } from 'react-query';
import { Box } from '@leagueplatform/ui-kit';
import { RegionRadioInput } from 'components/region-selection/region-selection-radio.component';

type RegionFormData = {
  selectedRegion: string;
};

export const RegionSelectionForm = ({ onClose }: { onClose: () => void }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedRegion, regionOptions } = useContext(RegionSelectionContext);
  const [newSelectedRegion, setNewSelectedRegion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const setUserRegion = useSetUserProfile({ highmark_ubk: newSelectedRegion });
  const queryClient = useQueryClient();
  const methods = useForm<RegionFormData>();
  const isImpersonator = useGetIsImpersonator();

  useEffect(() => {
    const oldUbk = selectedRegion?.brandUbk;
    const userProfileCall = async () => {
      try {
        await setUserRegion();
      } catch {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_HEADING_GENERIC',
        });
      }
    };

    // this feature does not work in impersonation mode, we want to display a custom modal and end the request
    if (isImpersonator) {
      return reportAgentPermissionError(true);
    }

    if (oldUbk && newSelectedRegion) {
      // Didn't simplified down to the base part, As this logic is accessible only to the base routes.
      const currentRoute = history.location.pathname;

      // update the user profile if the user has selected a new option
      if (oldUbk !== newSelectedRegion) {
        setIsLoading(true);
        userProfileCall().then(() => {
          // invalidate all queries so all app session data will be hard reloaded
          queryClient.removeQueries();
          setIsLoading(false);
          onClose();
          // replace history stack
          history.replace('/');

          // return the user to their original route after selecting the new plan.
          history.push(currentRoute);

          // hard refresh so all SDK in-memory data is reset
          window.location.reload();
        });
        // close the modal if the user keeps their current option
      } else {
        onClose();
      }
    }

    // cleanup
    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSelectedRegion, selectedRegion?.brandUbk]);

  if (!regionOptions || !selectedRegion || isLoading) {
    return (
      <Box marginTop="150px">
        <LoadingSpinner />
      </Box>
    );
  }

  const onSubmit = (data: RegionFormData): void =>
    setNewSelectedRegion(data.selectedRegion);

  return (
    <FormProvider
      formOptions={{
        mode: 'onSubmit',
        defaultValues: { selectedRegion: selectedRegion.brandUbk },
      }}
      onSubmit={onSubmit as any}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...methods}
    >
      <ControlledFieldset
        legend={formatMessage({ id: 'SELECT_PLAN' })}
        hideLegend
        name="selectedRegion"
        id="selectedRegion"
        inputOptions={{}}
      >
        {regionOptions.map((region) =>
          region?.brandUbk && region?.planName ? (
            <RegionRadioInput
              key={region.brandUbk}
              id={region.brandUbk}
              name="selectedRegion"
              label={region.planName}
              value={region.brandUbk}
              hint={region.planType || ''}
            />
          ) : null,
        )}
      </ControlledFieldset>
      <Button
        type="submit"
        priority="primary"
        size="large"
        width="fillContainer"
        css={{ marginTop: '$two', marginBottom: '$half' }}
      >
        {formatMessage({ id: 'VIEW_PLAN' })}
      </Button>
      <Button
        priority="tertiary"
        quiet
        width="fillContainer"
        css={{ marginY: '$half' }}
        onClick={onClose}
      >
        {formatMessage({ id: 'CANCEL' })}
      </Button>
    </FormProvider>
  );
};
