import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

export const SupportSubPathRedirect = () => {
  const location = useLocation();
  const match = location.pathname.match(/^\/[\w-]+/);
  const basePath = match?.length ? match[0] : '';
  const targetPath =
    basePath === '/support'
      ? location.pathname
      : location.pathname.replace(basePath, `/support${basePath}`);
  return (
    <Redirect
      to={{
        ...location,
        // Matches first path string before non character value (/, ?, etc) and adds /support before it, e.g. /old-path/same -> /support/old-path/same
        pathname: targetPath,
      }}
    />
  );
};
