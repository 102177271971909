import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const DuplicatePathRedirect = () => {
  const location = useLocation();
  const [newPath, setNewPath] = useState<string>();

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    // Removes a duplicate path if present at the start of the URL, e.g. /benefits/benefits/id -> /benefits/id
    if (pathArr[1] === pathArr[2]) {
      pathArr.splice(2, 1);
      setNewPath(pathArr.join('/'));
    }
  }, [location.pathname]);

  if (newPath) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: newPath,
        }}
      />
    );
  }

  return null;
};
