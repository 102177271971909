import React from 'react';
import { injectHomePageQualtricsScript } from 'utils/inject-home-page-qualtrics-script';

export const AboutMeRoutes = React.lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const WalletRoutes = React.lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const AchievementsRoutes = React.lazy(() =>
  import('@leagueplatform/rewards-achievements').then((module) => ({
    default: module.AchievementsRoutes,
  })),
);

export const HealthProfileRoutes = React.lazy(() =>
  import('@leagueplatform/health-profile').then((module) => ({
    default: module.HealthProfileRoutes,
  })),
);

export const CareCollectionsRoutes = React.lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);

export const CareDiscoveryRoutes = React.lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);

export const ClaimsRoutes = React.lazy(() =>
  import('@leagueplatform/claims').then((module) => ({
    default: module.ClaimsRoutes,
  })),
);

export const CoordinationOfBenefitsRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-cob-library').then((module) => ({
    default: module.CobLibraryRoutes,
  })),
);

export const AccountManagementRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-account-management').then(
    (module) => ({
      default: module.AccountManagementRoutes,
    }),
  ),
);

export const HealthJourneyRoutes = React.lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);

export const MessagingRoutes = React.lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const HomePage = React.lazy(async () => {
  injectHomePageQualtricsScript();

  return import('pages/home/home.page').then((module) => ({
    default: module.AppHomePage,
  }));
});

export const SupportPage = React.lazy(() =>
  import('pages/support-page.page').then((module) => ({
    default: module.AppSupportPage,
  })),
);

export const LiveChatPage = React.lazy(async () => {
  const module = await import('@leagueplatform/live-chat');
  return {
    default: module.LiveChatRoutes,
  };
});

export const GlossaryRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-insurance-glossary').then(
    (module) => ({
      default: module.InsuranceGlossaryRoutes,
    }),
  ),
);

export const EasyPlanFAQRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-easy-plan-faq').then((module) => ({
    default: module.EasyPlanFAQRoutes,
  })),
);

export const EasyPlanRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-easy-plan-home').then((module) => ({
    default: module.EasyPlanRoutes,
  })),
);

export const DocumentCenterRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-document-center').then((module) => ({
    default: module.DocumentCenterRoutes,
  })),
);
